import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margins, colors, mobileThresholdPixels } from '../../../components/home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.right ? 'flex-end' : 'center'};
  align-items: center;
  margin: ${margins.s} 0px;
  ${props => props.big && `margin: ${margins.l} 0px`};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.xs} 0px ${margins.s} 0px;
    ${props => props.big && `margin: ${margins.m} 0px`};
  }
`;

const Dot = styled.div`
  border: 2px solid ${colors.navy};
  ${props => props.big && 'border-width: 3px'};
  border-radius: 100%;
  ${props => props.middle && `margin: 0px ${margins.s}`};
`;

const Dots = ({ big, right }) => (
  <Container big={big} right={right}>
    <Dot big={big} />
    <Dot big={big} middle />
    <Dot big={big} />
  </Container>
);

Dots.propTypes = {
  big: PropTypes.bool,
  right: PropTypes.bool,
};

Dots.defaultProps = {
  big: false,
  right: false,
};

export default Dots;

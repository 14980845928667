import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Slider from './Slider';
import { mobileThresholdPixels, margins } from './StyledComponents';

import arrowLeft from '../../../tilli-components/src/assets/arrow.left.navy.svg';
import arrowRight from '../../../tilli-components/src/assets/arrow.right.navy.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: ${margins.m} ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    justify-content: space-around;
    margin: 0px;
  }
`;

const ControlContainer = styled.div`margin: 0px ${margins.s}`;

const ControlImg = styled.img`
  width: 20px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
  }
`;

const Img = styled.img`
  width: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 60px;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px ${margins.s};
  opacity: 0.4;
  ${props => props.isSelected && 'opacity: 1'};
  width: 150px;
  height: 150px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 80px;
    height: 80px;
    margin: 0px;
  }
`;

class ArticlesSelector extends React.Component {
  articleSelector(article) {
    return (
      <ArticleContainer
        key={article.index}
        isSelected={article.index === this.props.selectedArticleIndex}
        onClick={() => this.changeArticles(article.index)}
      >
        <Img src={article.logo} alt={article.logoCaption} />
      </ArticleContainer>
    );
  }

  changeArticles(newIndex) {
    const { selectedArticleIndex } = this.props;
    if (this.track.nextIndex(selectedArticleIndex) === newIndex) this.track.next();
    else if (this.track.prevIndex(selectedArticleIndex) === newIndex) this.track.prev();
    this.props.changeArticles(newIndex);
  }

  render() {
    const { selectedArticleIndex } = this.props;
    return (
      <Container>
        <ControlContainer>
          <ControlImg
            src={arrowLeft} onClick={() => this.changeArticles(this.track.prevIndex(selectedArticleIndex))}
          />
        </ControlContainer>
        <Slider
          preserveState infinite
          viewsToShow={3}
          ref={(c) => { this.track = c; }}
        >
          {this.props.articlesList.map(article => this.articleSelector(article))}
        </Slider>
        <ControlContainer>
          <ControlImg
            src={arrowRight} onClick={() => this.changeArticles(this.track.nextIndex(selectedArticleIndex))}
          />
        </ControlContainer>
      </Container>
    );
  }
}

ArticlesSelector.propTypes = {
  articlesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedArticleIndex: PropTypes.number.isRequired,
  changeArticles: PropTypes.func.isRequired,
};

export default ArticlesSelector;
